input {
    border: 0;
    box-shadow: 0 0 5px rgb(0, 0, 0, 0.25);
    padding: 1rem;
    margin-bottom: 0.5rem;
  }

  .todoControls {
    margin-top: 1rem;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .todoList {
    padding: 0 2rem;
    border-right: 1px dashed rgb(0, 0, 0, 0.25);
    height: 100vh;
  }
  
  form {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .todo__list {
    overflow-y: scroll;
    height: 100%;
    padding: 1rem;
  }

  .todo__list > li {
    margin-bottom: 1rem;
    max-width: 33vw;
    padding: 1.5rem;
    font-weight: bold;
  }

  .fa-trash {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .fa-trash--todo {
    color: var(--main-list-color);
    position: absolute;
    right: 25px;
  }
  
  .fa-trash:hover {
    transform: scale(1.2);
  }

  