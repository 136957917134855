.calendar {
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 100vh;
    padding: 2rem;
  }

  .calendar__times {
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    height: 100%;
    align-items: center;
  }

  .calendar__times > h1 {
    padding-right: 2rem;
  }

  .calendar__times > h1::after {
    padding-top: 1rem;
    margin-left: 1rem;
    content: '';
    border-bottom: 2px dashed rgb(0, 0, 0, 0.25);
    height: 1px;
    width: 50%;
		position: absolute;
		z-index: -1;
  }

  /* This is the list that holds all the tasks */
  .calendar__list {
    width: auto;
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    height: 100%;
    margin: 0;
    margin-top: 3rem;
    align-items: center;
    grid-gap: 7px;
  }

  .calendar__list > li {
    display: grid;
    align-items: start;
    justify-content: center;
    grid-template-columns: 1fr auto auto auto;
    grid-gap: 15px;
    padding: 0 1rem;
    height: 100%;
  }

  .calendar__list > li > p {
    padding-top: 1rem;
    font-weight: bold;
  }

  .calendar__list > li > .fas {
    color: var(--main-list-color);
  }

  .calendar__list > li > .fa-plus-circle, .fa-minus-circle {
    padding-top: 2.2rem;
    font-size: 1.3rem;
  }

  .calendar__list > li > .fa-trash--calendar{
    padding-top: 2.1rem;
  }

  .calendar__list > li > .fa-plus-circle:hover, .fa-minus-circle:hover {
    padding-top: 2rem;
    transform: scale(1.2);
  }

  .calendar__list > li > .fa-trash--calendar:hover {
    padding-top: 2rem;
  }

  .calendar__dropZone {
    height: 100%;
    padding: 0 1rem;
  }

  .calendar__dropZone--canDrop {
    border: 3px dashed var(--canDrop);
    border-radius: 5px;
  }

  .calendar__dropZone--isOver {
    border: 3px dashed var(--isOver);
    border-radius: 5px;
  }


