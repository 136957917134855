:root {
  --main-list-color: #243665;
  --hover-list-color: #8BD8BD;
  --list-font-color: white;
  --canDrop: rgb(241, 255, 51);
  --isOver: rgb(0, 255, 64);
}

#root {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 2fr;
  margin: 1rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  position: relative;
  background: var(--main-list-color);
  border: 3px solid var(--main-list-color);
  color: var(--list-font-color);
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: background-color, color; 
}

li:hover {
  background: var(--hover-list-color);
  border: 3px solid var(--hover-list-color);
}

button {
  background: #f0f0f0;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.25);
}




